<template>
  <v-container>

<h2>Bilunderhållning</h2>
<h5>Alla svar rättas efter rallyt och kan ge er tidsavdrag</h5>
<v-container
>
  <div v-if="fetchingResults" class="center">
          <vue-spinner />
      </div>
  <v-row
    v-else
    no-gutters
    style="height: 100px;"
  >
    <v-col
      cols="6"
      sm="3"
      v-for="n in numSplekQuestions"
      :key="n"
    >
      <v-sheet v-if="answeredQuestions.includes(n)" @click="openSubmitDialog(n)" class="pa-6 ma-3 primary rounded white--text text-h6">
        {{ n }}
      </v-sheet>
      <v-sheet v-else @click="openSubmitDialog(n)" class="pa-6 ma-3 grey rounded white--text text-h6">
        {{ n }}
      </v-sheet>
    </v-col>
  </v-row>
</v-container>

<div class="text-center">
  <v-dialog
    v-model="dialog"
    persistent
    max-width="290"
  >
    <v-card>
      <v-card-text class="pt-4">
        <h2 class="primary--text pb-2">Svara på fråga #{{ dialogNumber }}</h2>
        <v-form ref="form" :disabled="splekLoading" lazy-validation>
          <v-select
          v-model="answer"
          required
          label="Svar"
          placeholder="Rätt svar" 
          :items="['1', 'X', '2']"
          ></v-select>
        </v-form>
        <div v-if="splekLoading" class="center">
              <vue-spinner />
            <p>Skickar in svar...</p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          color="primary"
          @click="submitSplekAnswer"
          :disabled="splekLoading"
        >
          Skicka svar
        </v-btn>
        <v-btn
          text
          @click="dialog = false"
        >
          Stäng
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>

</v-container>

</template>

<script>
import { mapActions, mapGetters } from "vuex"
import {notify} from '../../utils/notification'
import Spinner from "vue-simple-spinner"

export default {
  name: 'SplekAnswers',
  components: {
    vueSpinner: Spinner
  },
  data: () => ({
    answer: '',
    numSplekQuestions: '',
    dialog: false,
    dialogNumber: null,
    answeredQuestions: [],
    answers: [],
    fetchingResults: true,
    timer: null
  }),
  methods: {
    ...mapActions(["sendSplekAnswer", "clearNotifications", "getAnsweredSplekQuestions", "getTeam"]),
    async submitSplekAnswer() {
      this.sendSplekAnswer({
        splekQuestionNumber: this.dialogNumber,
        answer: this.answer
      }).then(async () => {
        await this.updateAnsweredSplekQuestions()
        this.dialog = false
        // Notification
        notify(this)
      })
    },
    openSubmitDialog(splekQuestionNumber) {
      this.dialog = true
      this.dialogNumber = splekQuestionNumber

      // Check if trickyQuestionNumber is in answeredQuestions
      if (this.answeredQuestions.includes(splekQuestionNumber)) {
        this.answer = this.answers[splekQuestionNumber]
      } else {
        this.answer = ''
      }
    },
    closeSubmitDialog() {
      this.dialog = false
    },
    async updateAnsweredSplekQuestions() {
      const data = await this.getAnsweredSplekQuestions()
      this.answeredQuestions = data.answeredQuestions
      this.answers = data.answers
    },
    shuffle(array) {
      // Used for gamla rallyt
      let currentIndex = array.length,  randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex > 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    },
    async getUserTeam() {
      const team = await this.getTeam()
      this.team = team
      if(team.t_gamla_rallyt){
        this.numSplekQuestions = this.shuffle(Array.from({length: parseInt(process.env.VUE_APP_MAX_SPLEK)}, (_, i) => i + 1));
      } else {
        this.numSplekQuestions = Array.from({length: parseInt(process.env.VUE_APP_MAX_SPLEK)}, (_, i) => i + 1);
      }
    },
  },
  mounted() {
    this.getUserTeam()
    this.updateAnsweredSplekQuestions().then(() => {
      this.fetchingResults = false
    })
    this.timer = setInterval(() => {
      this.updateAnsweredSplekQuestions()
    }, 5000)
  },
  computed: mapGetters(["notification", "splekLoading"]),
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style>

</style>